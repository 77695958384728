<template>
    <div class="video-page-container">
        <loading :opacity="0" :active.sync="loading" :is-full-page="true" color="#0d47a1" :can-cancel="true"></loading>

        <v-app-bar class="opacity-bar" color="blue darken-4" dense elevation="4">
            <v-btn color="primary" elevation="2" @click.native="$router.go(-1)">
                <v-icon large>mdi-arrow-left</v-icon>
            </v-btn>
        </v-app-bar>



        <div class="videos-container mt-16">
            <div class="info-text">
                <h3>Your video may be being produced</h3>
                <h4>If your video is not yet on the list, please wait a few seconds until it is ready and appears in this list. This page updates automatically. </h4> <br>
            </div>
            <v-chip class="chip ma-2" color="primary" label v-for="item in formattedVideos" @click.native="downloadVideo(item.url)">
                <v-icon left>
                    mdi-video
                </v-icon>
                {{ item.name }}
            </v-chip>
        </div>

    </div>
</template>
  
<script>
import Api from "@/lib/Api";
import { emitToastr } from "@/lib/Utils";
import Loading from 'vue-loading-overlay';
var timer;

export default {
    name: "VideosView",

    data() {
        return {
            videos: [],
            formattedVideos: [],
            autoRefreshInterval: 30,
            loading: true
        };
    },

    components: {
        Loading
    },

    created() {
        timer = setInterval(() => {
            if (this.$route && this.$route.params && this.$route.params.project_name) {
                this.getVideos();
            }
        }, this.autoRefreshInterval * 1000)
    },

    beforeDestroy() {
        clearInterval(timer)
    },

    async mounted() {
        if (this.$route && this.$route.params && this.$route.params.project_name) {
            this.project = this.$route.params.project_name;
            await this.getVideos();
        }
        else {
            return emitToastr('error', 'Project not found');
        }
    },

    methods: {
        async getVideos() {
            this.loading = true;
            const resp = await Api.getVideos(this.project);
            if (resp && !resp.error && resp.message) {
                this.videos = resp.message;
                this.videos = this.videos.sort(() => -1)

                this.formattedVideos = [];
                this.videos.forEach(v => {
                    this.formattedVideos.push({ name: v.replace(v.split('_')[0], '').replace('_', ''), url: v });
                })
            }

            this.loading = false;
        },

        async downloadVideo(videoLink) {
            window.open(videoLink, '_blank', 'download');

            /*
            const video = await fetch(videoLink)
            const videoBlob = await video.blob()
            const videoURL = URL.createObjectURL(videoBlob)

            const link = document.createElement('a')
            link.href = videoURL
            link.download = videoLink.split('/')[videoLink.split('/').length - 1];
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
            */
        }
    },
};
</script>
  
<style scoped>
.video-page-container {
    background-color: #2d2d35;
    height: 100vh !important;
    overflow-y: auto;
}

.videos-container {
    display: flex;
    flex-direction: column;

}

.chip {
    cursor: pointer;
}

.info-text {
    color: #1976d2 !important;
    padding: 20px;
    padding-bottom: 0px;
}

@media screen and (max-width: 599px) {}
</style>
  