<template>
    <div v-if="!loading" class="comparison-container">
        <v-app-bar class="opacity-bar" color="blue darken-4" dense elevation="4">
            <v-btn class="upper-btn" color="primary" elevation="2" @click.native="$router.push(`/project/${project}/`)">
                <v-icon large>mdi-home-outline</v-icon>
            </v-btn>
        </v-app-bar>

        <div>
            <img-comparison-slider class="comparison">
                <v-img class="first-image" slot="first" contain :src="leftImage.url" :style="`transform: scale(${1 + zoom / 25}) translate(0, ${y_axis}px);`"></v-img>
                <v-img class="second-image" slot="second" contain :src="rightImage.url" :style="`transform: scale(${1 + zoom / 25}) translate(0, ${y_axis}px);`"></v-img>
            </img-comparison-slider>


            <div class="controls-container">
                <v-btn class="control-button-large" outlined dark @click.native="side = 'left'; $refs.dateTimePicker.display = true">LEFT - {{ leftImage.localeDateTime }}</v-btn>
                <v-slider class="zoom mb-2" label="Zoom" dark color="indigo lighten-5" v-model=" zoom " min="-25" max="100" hide-details></v-slider>

                <v-slider class="zoom mb-2" label="Y Pos" dark color="indigo lighten-5" v-model=" y_axis " min="0" max="700" hide-details></v-slider>
                <v-btn class="control-button-large" outlined dark @click.native=" side = 'right'; $refs.dateTimePicker.display = true ">RIGHT - {{ rightImage.localeDateTime }}</v-btn>
            </div>

        </div>

        <v-datetime-picker class="date-time-picker" ref="dateTimePicker" v-model=" date " v-on:input=" calendarDateChanged " :textFieldProps=" { width: '0', height: '0' } ">
            <template slot="dateIcon">
                <v-icon>mdi-calendar</v-icon>
            </template>

            <template slot="timeIcon">
                <v-icon>mdi-clock-outline</v-icon>
            </template>

            <template slot="actions" slot-scope="{ parent }">
                <v-btn color="success darken-1" @click=" parent.okHandler ">OK</v-btn>
            </template>
        </v-datetime-picker>


    </div>
</template>
  
<script>
import Api from "@/lib/Api";
import { emitToastr } from "@/lib/Utils";


export default {
    name: "ComparisonView",

    data() {
        return {
            images: [],
            leftImage: '',
            rightImage: '',
            project: '',
            loading: true,
            zoom: 0,
            y_axis: 0,
            side: 'left',
            date: '',
        };
    },

    async mounted() {
        if (this.$route && this.$route.params && this.$route.params.project_name) {
            this.project = this.$route.params.project_name;
            document.title = `Timelapse - ${this.project}`;
            await this.getImages();
        }
        else {
            return emitToastr('error', 'Project not found');
        }
    },

    components: {},

    methods: {
        async getImages() {
            this.loading = true;
            const resp = await Api.listAllFilesByProject(this.project);

            if (resp && resp.error) return emitToastr('error', resp.error.message);

            if (resp && !resp.error && resp.message) {
                this.images = resp.message;
                this.images.forEach((t, index) => {
                    t.localeDateTime = new Date(Number(t.unixTime) * 1000).toLocaleString();
                    t.localeDate = new Date(Number(t.unixTime) * 1000).toLocaleDateString();
                    t.index = index;
                })
            }

            if (this.images.length > 1) {
                this.leftImage = this.images[this.images.length - 1];
                this.rightImage = this.images[this.images.length - 2];
            }

            this.loading = false;
        },


        calendarDateChanged(e) {
            const now = new Date(e).getTime() / 1000;

            let smallDiff = Infinity;
            let smallIndex = -1;
            let diff;

            this.images.forEach(i => {
                diff = now - i.unixTime;
                if (diff < 0) diff = diff * (-1);

                if (diff < smallDiff) {
                    smallDiff = diff;
                    smallIndex = i.index;
                }
            })

            if (this.side === 'left') {
                this.leftImage = this.images[smallIndex];
            }
            else {
                this.rightImage = this.images[smallIndex];
            }
        }

    },
};
</script>
  
<style scoped>
.comparison-container {
    background-color: rgb(45, 45, 53);
    max-width: 100vw;
    max-height: 100vh;
    overflow: hidden;
}

.first-image {
    max-height: 100vh;
    max-width: 100vw;
}

.second-image {
    max-height: 100vh;
    max-width: 100vw;
}

.zoom {
    margin-left: 20px;
    margin-right: 20px;
}

.controls-container {
    position: absolute;
    z-index: 999999;
    width: 100vw;
    bottom: 0px;
    display: flex;
    color: white;
    background-color: rgba(45, 45, 53, 0.75);
    padding: 5px 40px 5px 5px;
    flex-direction: row;
    justify-content: space-around;
}

.upper-buttons-container {
    position: absolute;
    margin-left: 15px;
    margin-top: 15px;
    z-index: 9999999;
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    width: 100vw;
}

.date-time-picker {
    width: 0 !important;
    height: 0 !important;
    display: none !important;
    position: absolute !important;
    z-index: 0 !important;
}

.upper-btn {
    margin: 7px;
    z-index: 9999999;

}

@media screen and (max-width: 599px) {
    .upper-btn {
        right: 20px;
    }

    .comparison {
        height: 100vh;
    }

    .controls-container {
        display: flex;
        flex-direction: column;
        padding: 5px
    }

    .control-button-large {
        margin-bottom: 10px;
    }
}
</style>
  