<template>
    <div class="login_container">
        <h1 class="h1-title">TimelapseObras.com Viewer</h1>
        <v-card class="login_card" elevation="2">
            <v-card-title>Enter</v-card-title>
            <v-card-subtitle>Please enter your access credentials</v-card-subtitle>

            <v-card-text>
                <v-row>
                    <v-col cols="12">
                        <v-text-field v-model="project_name" label="Project name" outlined hide-details="auto"></v-text-field>
                    </v-col>
                    <v-col cols="12">
                        <v-text-field v-model="password" type="password" label="Password" outlined hide-details="auto"></v-text-field>
                    </v-col>
                </v-row>
            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn depressed large color="primary" @click.native="login">
                    Enter
                </v-btn>
            </v-card-actions>
        </v-card>
    </div>
</template>
  
<script>
import Api from "@/lib/Api";
import { emitToastr } from "@/lib/Utils";

export default {
    name: "LoginView",

    data() {
        return {
            project_name: "",
            password: "",
            remember: true,
        };
    },

    components: {},

    methods: {
        async login() {
            const resp = await Api.userAuth(this.project_name, this.password);

            if (resp && resp.project) {
                this.$router.push(`/project/${resp.project}`);
                return;
            }

        }
    },
};
</script>
  
<style scoped>
.h1-title {
    color: #1976d2;
    margin-bottom: 20px;
}

.login_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    align-content: center;
    align-items: center;
}

.logo {
    max-width: 300px;
    max-height: 300px;
}

.login_card {
    width: 80vw;
    max-width: 400px;
}

@media only screen and (max-width: 768px) {
    .login_container {
        text-align: center;
        padding: 8%;
    }

}
</style>
  