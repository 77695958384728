<template>
  <v-app style="background-color: rgb(45, 45, 53);">
    <v-main v-if="$route.meta.layout === 'fullscreen'">
      <router-view></router-view>
    </v-main>

    <div v-if="$route.meta.layout === 'menu'">
      <v-progress-linear app indeterminate color="deep-purple lighten-3" class="loading-bar" v-show="showLoading"></v-progress-linear>

      <v-navigation-drawer class="drawer" v-model="drawer" :clipped="$vuetify.breakpoint.lgAndUp" width="270" app>
        <center class="avatar_container mt-6 mb-6">
          <div class="avatar">
            <img src="@/assets/logo.png" width="55" height="50" />
          </div>
          <h5 class="icon">{{ projectName }}</h5>
        </center>
        <v-divider />

        <v-list dense>
          <router-link to="/dashboard">
            <v-list-item link>
              <v-list-item-action class="item_icon">
                <v-icon class="icon">mdi-monitor-dashboard</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title class="menu-item">Dashboard</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </router-link>

          <router-link to="/teste1">
            <v-list-item link>
              <v-list-item-action class="item_icon">
                <v-icon class="icon">mdi-book-education-outline</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title class="menu-item">Cursos</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </router-link>

          <v-list>
            <v-list-group>
              <template v-slot:activator>
                <v-list-item-title class="menu-item">Customizações</v-list-item-title>
              </template>
              <template v-slot:prependIcon>
                <v-icon class="icon">mdi-cog-outline</v-icon>
              </template>

              <router-link to="/teste2">
                <v-list-item>
                  <v-list-item-icon class="item_icon">
                    <v-icon class="icon">mdi-palette</v-icon>
                  </v-list-item-icon>

                  <v-list-item-title class="menu-item">Cores</v-list-item-title>
                </v-list-item>
              </router-link>

              <router-link to="/teste3">
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon class="icon">mdi-dev-to</v-icon>
                  </v-list-item-icon>

                  <v-list-item-title class="menu-item">Configurações</v-list-item-title>
                </v-list-item>
              </router-link>
            </v-list-group>
          </v-list>
        </v-list>

      </v-navigation-drawer>

      <v-main class="main">
        <v-app-bar color="white" :clipped-left="$vuetify.breakpoint.lgAndUp" dark elevation="2">
          <v-app-bar-nav-icon @click.stop="drawer = !drawer" color="black"></v-app-bar-nav-icon>
          <v-toolbar-title class="toolbar">
            {{ projectName }}
          </v-toolbar-title>
          <v-spacer></v-spacer>

          <v-menu>
            <template #activator="{ on: menu }">
              <v-btn icon v-on="menu">
                <v-icon color="black">mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item style="cursor: pointer" @click="logout()">
                <v-list-item-title>Sair</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-app-bar>
        <v-container fluid class="menu-page-container">
          <router-view fluid />
        </v-container>
      </v-main>
    </div>
  </v-app>
</template>

<script>
import Api from "@/lib/Api";
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
  name: "App",

  data: () => ({
    drawer: false,
    showLoading: false,
    projectName: "Timelapse viewer",
    user: {},
    fullPage: true
  }),

  methods: {
    logout() {
      Api.logout();
    },

    setLoading(loading) {
      
    },
  },

  created() {
    document.title = 'Timelapse viewer';
    this.$root.$refs.global = this;
  },

  async mounted() {
    this.user = Api.getUser();
    if (this.user && this.user._id) await Api.getRemoteUser();

    this.$vuetify.breakpoint.lgAndUp ? (this.drawer = true) : (this.drawer = false);
  },
};
</script>

<style scoped>
a {
  text-decoration: none;
}

.menu-page-container {
  background-color: #eaeaea;
}

.toolbar {
  font-weight: 700;
  color: black;
}

.drawer {
  background-color: rgb(34, 34, 36);
  z-index: 100;
}

.icon {
  color: rgb(118, 161, 255);
}

.item_icon {
  margin-right: 16px !important;
}

.logo {
  position: relative;
  margin-top: 30px;
  margin-bottom: 25px;
  display: flex;
  justify-content: center;
  width: 100%;
}

.menu-item {
  color: white;
  font-weight: 700 !important;
  text-overflow: none !important;
}

.loading-bar {
  position: absolute;
  z-index: 99999 !important;
}

.avatar_container {
  margin-bottom: 10px;
}

.avatar {
  margin-top: 15px;
  margin-bottom: 10px;
}

.rotate_icon {
  transform: rotate(90deg);
}

.toolbar-subtitle {
  font-size: 16px;
  font-weight: 500;
}

.v-main {
  height: 100%;
  width: 100vw;
  position: fixed;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
}
</style>
