<template>
    <div v-if="!loading" class="timelapse-container">
        <v-app-bar class="opacity-bar" color="blue darken-4" dense elevation="4">
            <v-btn class="upper-btn" color="primary" elevation="2" @click.native="downloadImage(fullQualityImage)">
                <v-icon large>mdi-cloud-download-outline</v-icon>
            </v-btn>
            <v-btn class="upper-btn ml-3" color="primary" elevation="2" @click.native="resetImage">
                <v-icon large>mdi-image-filter-center-focus-weak</v-icon>
            </v-btn>
            <v-btn class="upper-btn ml-3" color="primary" elevation="2" @click.native="goToCompare">
                <v-icon large>mdi-compare</v-icon>
            </v-btn>
            <v-menu top close-on-click>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn class="upper-btn ml-3" v-bind="attrs" v-on="on" color="primary" elevation="2">
                        <v-icon large>mdi-video</v-icon>
                    </v-btn>
                </template>

                <v-list>
                    <v-list-item @click.native="videoDialog = true">
                        <v-list-item-title>Make a new video</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click.native="$router.push(`/project/${project}/videos`)">
                        <v-list-item-title>Download videos</v-list-item-title>
                    </v-list-item>

                </v-list>
            </v-menu>
        </v-app-bar>

        <div class="">
            <loading :opacity="0" :active.sync="imageLoading" :is-full-page="false" color="#0d47a1" :can-cancel="false"></loading>
            <div>
                <v-img :src="fullQualityImage" v-on:load="imageLoaded" style="display: none;"></v-img>

                <div id="scene">
                    <v-img id="element" contain :src="imageToRender" v-on:load="fitImage"></v-img>
                </div>

                <div class="controls-container">
                    <v-slider class="slider" label="Timeline" dark color="indigo lighten-5" v-model="selectedImage" hide-details thumb-size="0" :max="thumbs.length - 1" step="1" v-on:input="changeThumb" v-on:change="changeImage">
                        <template v-slot:thumb-label="{ value }">
                            <div class="slider-thumb" :style="`background-image:url('${thumbs[value].url}')`">
                            </div>
                        </template>
                    </v-slider>
                    <div class="buttons-container" v-if="$vuetify.breakpoint.xsOnly">
                        <v-btn class="control-button-large" small outlined dark @click.native="$refs.dateTimePicker.display = true">{{ thumbs[selectedImage].localeDateTime }} - {{ calcTime() }}</v-btn>

                        <div class="buttons-mobile-container">
                            <v-btn class="control-button" icon dark @click.native="previousDayImage">
                                <v-icon large>mdi-chevron-double-left</v-icon>
                            </v-btn>
                            <v-btn class="control-button" icon dark @click.native="previousImage">
                                <v-icon large>mdi-chevron-left</v-icon>
                            </v-btn>
                            <v-btn class="control-button" icon dark @click.native="nextImage">
                                <v-icon large>mdi-chevron-right</v-icon>
                            </v-btn>
                            <v-btn class="control-button" icon dark @click.native="nextDayImage">
                                <v-icon large>mdi-chevron-double-right</v-icon>
                            </v-btn>
                        </div>
                    </div>

                    <div class="buttons-container" v-if="!$vuetify.breakpoint.xsOnly">
                        <v-btn class="control-button" icon dark @click.native="previousDayImage">
                            <v-icon large>mdi-chevron-double-left</v-icon>
                        </v-btn>
                        <v-btn class="control-button" icon dark @click.native="previousImage">
                            <v-icon large>mdi-chevron-left</v-icon>
                        </v-btn>

                        <v-btn class="control-button-large" outlined dark @click.native="$refs.dateTimePicker.display = true">{{ thumbs[selectedImage].localeDateTime }} - {{ calcTime() }}</v-btn>

                        <v-btn class="control-button" icon dark @click.native="nextImage">
                            <v-icon large>mdi-chevron-right</v-icon>
                        </v-btn>
                        <v-btn class="control-button" icon dark @click.native="nextDayImage">
                            <v-icon large>mdi-chevron-double-right</v-icon>
                        </v-btn>
                    </div>

                    <div class="project-name">
                        Timelapse - {{ project }}
                    </div>
                </div>
            </div>
        </div>

        <v-datetime-picker class="date-time-picker" ref="dateTimePicker" v-model="date" v-on:input="calendarDateChanged" :textFieldProps="{ width: '0', height: '0' }">
            <template slot="dateIcon">
                <v-icon>mdi-calendar</v-icon>
            </template>

            <template slot="timeIcon">
                <v-icon>mdi-clock-outline</v-icon>
            </template>

            <template slot="actions" slot-scope="{ parent }">
                <v-btn color="success darken-1" @click="parent.okHandler">OK</v-btn>
            </template>
        </v-datetime-picker>


        <v-dialog v-model="videoDialog" persistent max-width="600">
            <v-card>
                <v-card-title class="text-h5">
                    Make a video
                </v-card-title>

                <v-card-text>
                    <b>Please select the time range (limit is 2 months), FPS and video resolution.</b><br><br>
                    Your video will be created asynchronously and, depending on the amount of frames, it may take a few minutes. <br><br>
                    You will be redirected to the videos screen where you can wait for this video to be ready. <br><br>

                    <v-dialog ref="dialog1" v-model="modalInitialDate" :return-value.sync="initialDate" persistent width="290px">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field v-model="initialDateFormatted" label="Initial date" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                        </template>
                        <v-date-picker v-model="initialDate" scrollable v-on:input="dateSelected('initial')">
                            <v-spacer></v-spacer>
                            <v-btn text color="primary" @click="modalInitialDate = false">
                                Cancel
                            </v-btn>
                            <v-btn text color="primary" @click="$refs.dialog1.save(initialDate); modalInitialDate = false">
                                OK
                            </v-btn>
                        </v-date-picker>
                    </v-dialog>


                    <v-dialog ref="dialog2" v-model="modalFinalDate" :return-value.sync="finalDate" persistent width="290px">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field v-model="finalDateFormatted" label="Final date" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                        </template>
                        <v-date-picker v-model="finalDate" scrollable v-on:input=" dateSelected('final')">
                            <v-spacer></v-spacer>
                            <v-btn text color="primary" @click=" modalFinalDate = false">
                                Cancel
                            </v-btn>
                            <v-btn text color="primary" @click=" $refs.dialog2.save(finalDate); modalFinalDate = false">
                                OK
                            </v-btn>
                        </v-date-picker>
                    </v-dialog>

                    <v-select v-model="fps" outlined :items="fps_types" item-text="name" item-value="id" attach label="Video FPS"></v-select>
                    <v-select v-model="resolution" outlined :items="resolution_types" item-text="name" item-value="id" attach label="Video Resolution"></v-select>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn color="green darken-1" text @click=" videoDialog = false">
                        Cancel
                    </v-btn>

                    <v-btn color="green darken-1" text @click="requestVideo" :disabled="!initialImage || !finalImage">
                        Make video
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
  
<script>
import Api from "@/lib/Api";
import { emitToastr } from "@/lib/Utils";
import Loading from 'vue-loading-overlay';
var timer;

var panZoomController;
var element;
var s;


export default {
    name: "ProjectView",

    data() {
        return {
            images: [],
            thumbs: [],
            selectedImage: 0,
            autoRefreshInterval: 60,
            selectType: 'thumbs',
            imageToRender: '',
            project: '',
            loading: true,
            imageLoading: true,
            fullQualityImage: '',
            date: '',
            fitOnLoad: true,
            videoDialog: false,
            fps: 10,
            resolution: "1280x?",
            initialDate: '',
            modalInitialDate: false,
            finalDate: '',
            modalFinalDate: false,
            initialImage: undefined,
            finalImage: undefined,
            initialDateFormatted: undefined,
            finalDateFormatted: undefined,
            resolution_types: [
                { id: "1280x?", name: "HD" },
                { id: "1920x?", name: "Full HD" },
            ],
            fps_types: [
                { id: 10, name: "10" },
                { id: 25, name: "25" },
            ]
        };
    },

    created() {
        timer = setInterval(() => {
            if (this.$route && this.$route.params && this.$route.params.project_name) {
                this.getImages(true);
            }
        }, this.autoRefreshInterval * 1000)
    },

    beforeDestroy() {
        clearInterval(timer)
    },

    async mounted() {
        if (this.$route && this.$route.params && this.$route.params.project_name) {
            this.project = this.$route.params.project_name;
            document.title = `Timelapse - ${this.project}`;
            await this.getImages(false);
        }
        else {
            return emitToastr('error', 'Project not found');
        }

        element = document.querySelector('#element')
        s = (document.querySelector('#scene').offsetWidth / 2) - (element.offsetWidth);


        panZoomController = panzoom(element, {
            bounds: true,
            boundsPadding: 0.1,
            initialZoom: 2,
            minZoom: 0.5
        })

        this.resetImage();
    },

    components: {
        Loading
    },

    methods: {
        async getImages(isRefreshing) {
            if (!isRefreshing) {
                this.loading = true;
                this.imageLoading = true;
            }
            const resp = await Api.listAllFilesByProject(this.project);

            if (resp && resp.error) return emitToastr('error', resp.error.message);

            if (resp && !resp.error && resp.message) {

                if (resp.message.length != this.images) {
                    this.images = resp.message;
                    this.thumbs = [];

                    this.images.forEach((t, index) => {
                        this.thumbs.push({
                            url: t.url.replace(`digitaloceanspaces.com/${this.project}/`, `digitaloceanspaces.com/${this.project}_thumb/`),
                            unixTime: t.unixTime,
                            localeDateTime: new Date(Number(t.unixTime) * 1000).toLocaleString(),
                            localeDate: new Date(Number(t.unixTime) * 1000).toLocaleDateString(),
                            index: index
                        })
                    })

                    this.selectedImage = this.images.length - 1;
                    this.imageToRender = this.images[this.selectedImage].url;
                    this.fullQualityImage = this.imageToRender;
                    this.loading = false;
                    window.dispatchEvent(new Event('resize'));
                }
            }

            if (!isRefreshing) {
                this.selectedImage = this.images.length - 1;
                this.imageToRender = this.images[this.selectedImage].url;
                this.fullQualityImage = this.imageToRender;
                this.loading = false;
                window.dispatchEvent(new Event('resize'));
            }
        },

        resetImage() {
            element = document.querySelector('#element')
            s = (document.querySelector('#scene').offsetWidth / 2) - (element.offsetWidth);

            const screenSize = {
                "w": Math.max(document.documentElement.clientWidth, window.innerWidth || 0),
                "h": Math.max(document.documentElement.clientHeight - 136, window.innerHeight - 136 || 0)
            };
            let initalZoom = (screenSize.w / element.offsetWidth < screenSize.h / element.offsetHeight) ? screenSize.w / element.offsetWidth : screenSize.h / element.offsetHeight;
            panZoomController.zoomAbs(0, 0, initalZoom);
            panZoomController.moveTo((screenSize.w - element.offsetWidth * initalZoom) / 2, (screenSize.h - element.offsetHeight * initalZoom) / 2);
        },

        changeThumb() {
            this.imageLoading = true;
            this.imageToRender = this.thumbs[this.selectedImage].url;
        },

        changeImage() {
            //this.imageLoading = true;
            this.fullQualityImage = this.images[this.selectedImage].url;
        },

        loadImage(index) {
            this.selectedImage = index;
            this.changeThumb();
            this.changeImage();
        },

        changeItem(e) {
            this.dialog = false;
            if (e != null) {
                this.loadImage(e);
            }
        },

        imageLoaded() {
            this.imageToRender = this.fullQualityImage;
        },

        fitImage() {
            if (this.fitOnLoad) {
                this.resetImage();
                this.fitOnLoad = false;
            }

            this.imageLoading = false;
        },

        nextImage() {
            if (this.selectedImage < (this.images.length - 1)) {
                this.loadImage(this.selectedImage + 1)
            }
        },

        previousImage() {
            if (this.selectedImage > 0) {
                this.loadImage(this.selectedImage - 1)
            }
        },

        previousDayImage() {
            for (let i = this.selectedImage; i >= 0; i--) {
                if (this.thumbs[this.selectedImage].localeDate != this.thumbs[i].localeDate) {
                    this.loadImage(i)
                    break;
                }
            }
        },

        nextDayImage() {
            for (let i = this.selectedImage; i < this.thumbs.length; i++) {
                if (this.thumbs[this.selectedImage].localeDate != this.thumbs[i].localeDate) {
                    this.loadImage(i)
                    break;
                }
            }
        },



        calcTime() {
            const imageTime = this.thumbs[this.selectedImage].unixTime * 1000;
            const now = new Date().getTime();

            let interval = (now - imageTime) / 1000; //IN SECONDS

            if (interval < 60) return `${interval} seconds ago`;

            interval = Math.floor(interval / 60); // CONVERTED IN MINUTES
            if (interval < 60) return `${interval} ${interval > 1 ? 'minutes' : 'minute'} ago`;

            let remainingMinutes = interval % 60;
            interval = Math.floor(interval / 60); // CONVERTED IN HOURS
            if (interval < 24) return `${interval}h ${remainingMinutes}min ago`;

            let remainingHours = interval % 24;
            interval = Math.floor(interval / 24); // CONVERTED IN DAYS
            return `${interval} ${interval > 1 ? 'days' : 'day'} ${remainingHours}h ago`;
        },

        calendarDateChanged(e) {
            const now = new Date(e).getTime() / 1000;

            let smallDiff = Infinity;
            let smallIndex = -1;
            let diff;

            this.thumbs.forEach(i => {
                diff = now - i.unixTime;
                if (diff < 0) diff = diff * (-1);

                if (diff < smallDiff) {
                    smallDiff = diff;
                    smallIndex = i.index;
                }
            })

            this.loadImage(smallIndex);
        },

        goToCompare() {
            this.$router.push(`/project/${this.project}/comparison`);
        },

        goToVideo() {
            this.videoDialog = true;
        },

        async downloadImage(imageSrc) {
            if (!this.fullQualityImage) return emitToastr('info', 'Please select an image');
            const image = await fetch(imageSrc)
            const imageBlog = await image.blob()
            const imageURL = URL.createObjectURL(imageBlog)

            const link = document.createElement('a')
            link.href = imageURL
            link.download = imageSrc.split('/')[imageSrc.split('/').length - 1];
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
        },

        dateSelected(type) {
            if (type === 'initial') {
                this.initialImage = this.findFirstDayImage(this.initialDate);
                const splitedDate = this.initialDate.split('-');
                this.initialDateFormatted = `${splitedDate[2]}/${splitedDate[1]}/${splitedDate[0]}`;
            }
            else {
                this.finalImage = this.findFirstDayImage(this.finalDate);
                const splitedDate = this.finalDate.split('-');
                this.finalDateFormatted = `${splitedDate[2]}/${splitedDate[1]}/${splitedDate[0]}`;
            }
        },

        findFirstDayImage(date) {
            const now = new Date(date).getTime() / 1000;

            let smallDiff = Infinity;
            let smallIndex = -1;
            let diff;

            this.thumbs.forEach(i => {
                diff = now - i.unixTime;
                if (diff < 0) diff = diff * (-1);

                if (diff < smallDiff) {
                    smallDiff = diff;
                    smallIndex = i.index;
                }
            })

            const result = this.thumbs[smallIndex]

            if (result) {
                emitToastr('success', 'Date selected.')
                return result;
            }
            else {
                emitToastr('info', 'There is no image at this date.')
                return undefined;
            }
        },

        async requestVideo() {
            if (!this.initialImage) return emitToastr('error', 'You need to select both images');
            if (!this.finalImage) return emitToastr('error', 'You need to select both images');

            if (this.initialImage.index > this.finalImage.index) return emitToastr('error', 'The initial image must be dated before the final image');

            this.videoDialog = false;

            let frames = [];
            for (let i = this.initialImage.index; i <= this.finalImage.index; i++) {
                frames.push(this.thumbs[i].url.replace(`/${this.project}/`, `/${this.project}_video/`));
            }

            if (frames.length > 4300) {
                return emitToastr('error', 'The time range limit is 2 months (approximately 4200 images). Please select a range within this limit and try again.');
            }

            const resp = await Api.requestVideo(this.project, this.fps, this.resolution, frames, this.initialDateFormatted, this.finalDateFormatted);
            if (resp === 'ok') {
                this.$router.push(`/project/${this.project}/videos`);
            }
        }
    },
};
</script>
  
<style scoped>
.image-container {
    background-color: #2d2d35;
    height: 100vh !important;
}

.slider {
    padding-right: 50px;
}

#scene {
    width: 100vw;
    height: 100vh;
    background-color: rgb(31, 31, 37);
    position: relative;
    overflow: hidden;
}

#element {
    width: 100%;
    height: auto;
}

.timelapse-container {
    background-color: rgb(45, 45, 53);
    max-width: 100vw;
    max-height: 100vh;
    overflow: hidden;
}

.slider-thumb {
    text-align: center;
    color: yellow;
    font-size: 18px;
    font-weight: bold;
    width: 250px;
    height: 250px;
    transform: translateY(-145px);
    border-radius: 5px;
    background-position-x: center;
    background-position-y: center;
    background-repeat: no-repeat;
    background-size: contain;
}

.buttons-container {
    display: flex;
    justify-content: center;
    z-index: 9999999;
    width: 100%;
}

.controls-container {
    position: absolute;
    bottom: 0px;
    width: 100vw;
    color: white;
    background-color: rgba(45, 45, 53, 0.75);
    padding: 5px 10px 10px 10px;
}

.upper-buttons-container {
    position: absolute;
    margin-left: 15px;
    margin-top: 15px;
    z-index: 9999999;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    align-content: center;
}

.date-time-picker {
    width: 0 !important;
    height: 0 !important;
    display: none !important;
    position: absolute !important;
    z-index: 0 !important;
}

.project-name {
    width: 100%;
    text-align: center;
    color: white;
    font-weight: bold;
}



@media screen and (max-width: 599px) {

    .slider {
        padding-right: 30px;
    }

    .controls-container {
        margin-right: 10px;
        padding-bottom: 5px;
    }

    .buttons-container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .buttons-mobile-container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        margin-top: 10px;
        margin-bottom: 1px;
    }
}
</style>
  