import Vue from "vue";
import VueRouter from "vue-router";
import Login from "../views/Login.vue";
import Project from "../views/Project.vue";
import Comparison from "../views/Comparison.vue";
import Videos from "../views/Videos.vue";
import Dashboard from "../views/Dashboard.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "login",
    component: Login,
    meta: {
      layout: "fullscreen",
    },
  },
  {
    path: "/project/:project_name",
    name: "Project",
    component: Project,
    meta: {
      layout: "fullscreen",
    },
  },
  {
    path: "/project/:project_name/comparison",
    name: "Comparison",
    component: Comparison,
    meta: {
      layout: "fullscreen",
    },
  },  
  {
    path: "/project/:project_name/videos",
    name: "Videos",
    component: Videos,
    meta: {
      layout: "fullscreen",
    },
  },  
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
    meta: {
      layout: "menu",
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  const publicPages = ["/"];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem("token");

  if (authRequired && !loggedIn) {
    next("/");
  } else {
    next();
  }
});

export default router;
